import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Forex = () => {
  const headingbanner = {
    title: `Forex`,
    content: `An In-Store Forex And CRM System`,
  };

  const data = {
    images: ["forex-casestudy.webp", "forex-casestudy-2.webp"],
    firstAlt: "UI Screen of Customer profile to check their exchange information",
    firstTitle: "Customer Profile",
    secondAlt: "Price calculator to check the market exchange rate",
    secondTitle: "Price Calculator",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing", "UI Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Our main challenge was to develop a high-quality forex trading platform that offers real-time exchange rate information and maintains a customer relationship management (CRM) system.`,
          `We wanted to ensure that our users could access the latest exchange rates effortlessly and that the platform could quickly respond to market changes. `,
          `Additionally, we had to incorporate visually appealing forex charts with indicators to help users make informed trading decisions and to simplify the estimation calculator, allowing users to easily determine the potential gains or losses from their trades.`,
          `We were also tasked to optimise the transaction process to ensure smooth and efficient execution of trades.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `Our main objective was to enhance the user interface by optimising its aesthetics for usability and delightfulness.`,
          `In order to reduce the strain on the user's eyes, we decided to implement a dark theme, which not only adds a sleek and modern look to the interface but also promotes a more comfortable user experience during extended periods of usage. `,
          `Additionally, we aimed to make efficient use of the available viewport space, ensuring that the interface elements are properly organised and easily accessible.`,
          `To provide our users with a vibrant and engaging experience, we integrated live information and transaction capabilities, allowing them to stay updated in real-time and perform various transactions seamlessly within the UI.`,
          `Lastly, we aimed to simplify the process of currency conversion, enabling users to effortlessly convert between different currencies with just a few clicks or taps.`,
          `Overall, our solution focuses on delivering an aesthetically pleasing, user-friendly, and efficient interface while enhancing the overall user experience.`,
        ],
      },
    ],
    image: ["forex-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Price Calculator",
    para: [
      `This screen shows the live currency rate along with the other information such as corridor selection, customer segment, volume and promotional offers to provide improved user experience. `,
    ],
    content: {
      image: ["forex-projectone.webp"],
      imageAlt: 'UI Screen to calculate price based on market fluctuations',
      imageTitle: 'Price Calculator',
    },
    
  };

  const projectData2 = {
    title: `Simulation`,
    para: [
      `We created this personalised screen wherein the users can exchange currency based on customer segment. The other values are calculated based on their exchange behaviour. `,
    ],
    content: {
      image: ["forex-projecttwo.webp"],
      imageAlt: 'UI Simulation Screen',
      imageTitle: 'Simulation',
    },
  };

  const projectData3 = {
    title: "Profiles",
    para: [
      `A personal profile of every customer assists users of the system to look into their purchase cycle, complaint history, promotion history, etc. to maintain better customer relations.`,
    ],
    content: {
      image: [
        "forex-projectthree.webp",
      ],
      firstAlt: 'UI Screen to check customer details, purchase cycle, etc.',
      firstTitle: 'Customer Profile',
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, our development of an in-store Forex and CRM system has successfully addressed the challenges of providing real-time exchange rate information and maintaining a customer relationship management system.`,
        `The integration of live information and transaction capabilities allows users to stay updated in real-time and seamlessly perform various transactions within the interface. `,
        `Furthermore, the simplification of the currency conversion process enables users to effortlessly convert between different currencies.`,
        `Overall, our solution delivers an aesthetically pleasing, user-friendly, and efficient interface, enhancing the overall user experience.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={35} />
      </div>
    </Layout>
  );
};

export default Forex;

export const Head = () => (
  <Seo title="POS Forex - Octet Design Studio" description="We provided full cycle UI UX design services from User research to UI Developmen to create superfast and dynamic forex trading platform." />
)